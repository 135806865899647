import { NormalizeAllArgs } from "../../types/entities"
import { BasicEntity, Dictionary, SelectOption } from "ftl-core"

export function basicNormalizeAll<
  EntityType extends BasicEntity & { name: string }
>({ items, setItems, labelProp = "name" }: NormalizeAllArgs<EntityType>) {
  const normalizedLabels: Dictionary<string> = {}
  const normalizedOptions: SelectOption<string>[] = []
  let defaultItem: EntityType | undefined = undefined
  items.forEach((item: EntityType) => {
    if (item[labelProp] && item.id) {
      normalizedLabels[item.id] = "" + item[labelProp]
      normalizedOptions.push({
        value: item.id,
        label: "" + item[labelProp],
      })
    }
    if (item.isDefault) defaultItem = item
  })
  setItems &&
    setItems({
      options: normalizedOptions,
      labels: normalizedLabels,
      default: defaultItem,
    })
  return normalizedLabels
}
