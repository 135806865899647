import { FTLSmartCreateDomainStore } from "ftl-dashboards-templates";
import CurrenciesCreateScheme from "../entity/CurrenciesCreateScheme";
import CurrenciesCreateEntity from "../entity/CurrenciesCreateEntity";
import CurrenciesCreacteApiRepository from "../../../../../infrastructure/api/AdditionalSettings/Currencies/CurrenciesCreacteApiRepository";

const CurrenciesCreateStore = FTLSmartCreateDomainStore<typeof CurrenciesCreateScheme, CurrenciesCreateEntity>({
  schema: CurrenciesCreateScheme,
  repository: CurrenciesCreacteApiRepository
})

export default CurrenciesCreateStore