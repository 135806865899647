import { makeStyles } from "@material-ui/core";

const LogChannelTelegramConfCreateStyles = makeStyles((theme) =>( {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginLeft: 0,
    }
  }
}))

export default LogChannelTelegramConfCreateStyles