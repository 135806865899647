import { Attendance, AttendanceTemplate } from "../../types/attendance";
import { Denormalized, FiltersState, FTLDateUtils, requiredMessage } from "ftl-core";
import React from "react";
import * as Yup from "yup"
import { PointOfSale } from "../../types/pointOfSale";

type normalizeFuncType = (
  { items }: Denormalized<Attendance<"GET">>,
  context: {
    setData: React.Dispatch<Attendance<"GET">[]>
    meta?: any,
    rerenderState: boolean,
    setRerenderState: Function
  }
) => Attendance<"GET">[]

export const initState: AttendanceTemplate = {
  employee: [],
  position: [],
  dateEnd: new Date(''),
  dateStart: new Date(''),
  status: "CLOSED"
}

export const normalizeAttendanceFilters = (filters: FiltersState) => ({
  query: filters.query
});

export const attendanceSchema = Yup.object({
  employee: Yup.string().required(requiredMessage),
  position: Yup.string().required(requiredMessage),
  dateStart: Yup.date().required(requiredMessage),
  dateEnd: Yup.date().required(requiredMessage),
  status: Yup.string().required(requiredMessage),
})

export const normalizeAttendance: (
  { items }: Denormalized<Attendance<"GET">>,
  context: { setData: React.Dispatch<Attendance<"GET">[]>; meta?: any },
  rerenderState: boolean,
  setRerenderState: Function
) => {
  id: string
  comment: string
  departmentId: string
  departmentTitle?: string
  managerConfirm: string
  employeeId: string
  employeeName?: string
  positionId: string
  positionName?: string
  duration: number
  status: "CLOSED" | "CONFIRMED" | "NEED_CONFIRM" | "OPEN" | "REJECTED"
  syncStatus: "NEED_SYNC" | "SYNC"
  uuid: string
  dateEnd: Date
  dateStart: Date
  createdAt: string
}[] = (
  { items }: Denormalized<Attendance<"GET">>,
  context: {
    setData: React.Dispatch<Attendance<"GET">[]>
    meta?: any
  }
) => {
  const meta = context.meta;
  const normalizeDataOfTable = items.map((item) => {
    return {
      ...item,
      departmentTitle:
        item.departmentId == null
          ? undefined
          : meta[0] && meta[0][item.departmentId],
      positionName:
      item.positionId == null
          ? "Не определен"
        : meta[1] && meta[1][item.positionId],
      createdAt: FTLDateUtils.format(new Date(item?.createdAt ?? ""), {
        breakHours: false,
      }),
    };
    console.log(normalizeDataOfTable)
  });
  return normalizeDataOfTable;
};


// export const normalizeAttendance: normalizeFuncType = ({ items }, context
// ) => {
//   const meta = context.meta
//   const normalizeDataOfTable = items.map((item) => {
//     return {
//       ...item,
//       departmentTitle:
//         item.departmentId == null
//           ? "Не определен"
//           : meta[0] && meta[0][item.departmentId]
//     }
//   })
//   return normalizeDataOfTable
// }