import SmartTableRepository
  from "ftl-dashboards-templates/dist/domains/FTLSmartTableDomain/repositories/SmartTableRepository";
import CurrenciesCreateEntity from "../../../../domains/AdditionalSettings/Currencies/CurrenciesCreate/entity/CurrenciesCreateEntity";
import SmartCreateRepository
  from "ftl-dashboards-templates/dist/domains/FTLSmartCreateDomain/repositories/SmartCreateRepository";
import { API_BASE_URL } from "ftl-dashboards-templates/dist/infrastructure/api/auth/routers/AuthRouter";
import { BasicResponse } from "ftl-core";
import BaseApiRepository from "../../base/BaseApiRepository";

const CurrenciesCreacteApiRepository: SmartCreateRepository<CurrenciesCreateEntity> = {
  create: (entity: CurrenciesCreateEntity) => {
    return new Promise<BasicResponse<CurrenciesCreateEntity>>(async (resolve) => {
      const result = await BaseApiRepository.post<BasicResponse<CurrenciesCreateEntity>>(`${API_BASE_URL}/api/v1/currencies`, entity)
      resolve ({
        result: result.data.result
      })
    })
  }
}

export default CurrenciesCreacteApiRepository