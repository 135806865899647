import { array, string } from "efform";

const LogChannelTelegramConfCreateScheme = {
  chatId: string().required("Обязательное поле"),
  title: string().required("Обязательное поле"),
  key: string().required("Обязательное поле"),
  types: array({
    value: string(),
    label: string()
  }, [])
}

export default LogChannelTelegramConfCreateScheme