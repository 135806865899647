import {
  CityIcon,
  CourierIcon,
  FolderIcon,
  MapIcon,
  PeopleIcon,
  theme,
} from "ftl-uikit"

import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory"

import { Routes } from "./Routes"

import { createStore } from "redux"
import { UIMain } from "ftl-dashboards-templates"
import { AuthAPI, ProfileAPI } from "./repository"
import {
  basicReducer,
  handleError,
  initialState,
  logOutAction,
  setProfileAction,
} from "ftl-core"
import {
  GiteIcon,
  KitchenIcon,
  PosIcon,
  SourceFolderIcon,
} from "./shared/util/icons"
import OrderWaybillIcon from "./shared/util/icons/OrderWaybillIcon"
import OrderWaybillTemplateIcon from "./shared/util/icons/OrderWaybillTemplateIcon"
import CorpStructureIcon from "./shared/util/icons/CorpStructureIcon"
import { projectTitle } from "./shared/constants"

const initStateWithPageSize10 = {
  ...initialState,
  globalStore: { ...initialState.globalStore, pageSize: 10 },
}

const store = createStore(
  basicReducer,
  initStateWithPageSize10,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const App = () => {
  return (
    <UIMain
      redirectOnAuthUrl="/cities"
      project={{
        title: projectTitle,
      }}
      theme={theme}
      AuthClient={AuthAPI}
      StoreProviderProps={{
        store,
        getProfile: () => {
          //заглушка: единственный способ избежать вытягивания профиля при загрузке приложения
          return Promise.resolve({
            data: {
              result: {
                id: "",
                createdAt: "",
                email: "",
                firstName: "",
                lastName: "",
                accountStatus: "",
              },
            },
            status: 200,
            statusText: "OK",
            headers: {},
            config: {},
          })
        },
      }}
      BaseLayoutProps={{
        onMenuClick: async () => {
          if (!store.getState().globalStore.profile?.id)
            try {
              const profile = (await ProfileAPI.get()).data.result
              store.dispatch(setProfileAction(profile))
            } catch (error: any) {
              handleError({
                error,
                defaultError: "Произошла ошибка при получении данных профиля",
              })
            }
        },

        onSignOut: () => store.dispatch(logOutAction),
        sideMenuItems: [
          {
            icon: <SourceFolderIcon />,
            label: "Орг. структура",
            to: "/org-structures/",
          },
          {
            icon: <StoreMallDirectoryIcon />,
            label: "Департаменты",
            to: "/departments",
          },
          {
            icon: <PeopleIcon />,
            label: "Юр. лица",
            to: "/legal-units",
          },
          {
            icon: <GiteIcon />,
            label: "Склады",
            to: "/warehouses/",
          },
          {
            icon: <PosIcon />,
            label: "POS(Point Of Sale)",
            to: "/points-of-sale/",
          },
          {
            icon: <KitchenIcon />,
            label: "Места хранения",
            to: "/storage-addresses/",
          },
          {
            icon: <CityIcon />,
            label: "Города",
            to: "/cities/",
          },
          {
            icon: <FolderIcon />,
            label: "Iiko",
            to: "/ddd",
            items: [
              {
                label: "iikoSyncConf",
                to: "/iiko-sync-confs",
              },
              {
                label: "IikoSyncTaskParam",
                to: "/iiko-sync-task-params",
              },
              {
                label: "iikoSyncTaskLog",
                to: "/iiko-sync-task-logs",
              },
              {
                label: "iikoRequestLogs",
                to: "/iiko-request-logs",
              },
            ],
          },
          {
            icon: <PeopleIcon />,
            label: "Доступы ресторанов",
            to: "/user-waybills",
          },
          {
            icon: <OrderWaybillIcon />,
            label: "Заявки",
            to: "/order-waybills",
          },
          {
            icon: <OrderWaybillTemplateIcon />,
            label: "Шаблоны заявок",
            to: "/order-waybill-templates",
          },
          {
            icon: <OrderWaybillTemplateIcon />,
            label: "Номенклатуры",
            to: "/nomenclatures",
          },
          {
            icon: <MapIcon size="large" color="primary" />,
            label: "Location",
            to: "/location-plan-types/",
          },
          {
            icon: <MapIcon size="large" color="primary" />,
            label: "LocationFormat",
            to: "/location-formats/",
          },
          {
            icon: <CorpStructureIcon />,
            label: "Партнёры",
            to: "/corp-structures/",
          },
          {
            icon: <CourierIcon />,
            label: "Менеджер",
            to: "/managers/",
          },
          {
            icon: <SourceFolderIcon />,
            label: "Категории цен",
            to: "/price-categories/",
          },
          {
            icon: <PeopleIcon />,
            label: "Сотрудники",
            to: "/ttt",
            items: [
              {
                label: "Должности",
                to: "/positions",
              },
              {
                label: "Сотрудники",
                to: "/employees",
              },
              {
                label: "Явки",
                to: "/attendances",
              },
            ],
          },
          {
            icon: <FolderIcon />,
            label: "Конфигурация",
            to: "/ccc",
            items: [
              {
                label: "Валюты",
                to: "/Currencies",
              },
              {
                label: "Типы доставок",
                to: "/delivery-types",
              },
              {
                label: "Скидки",
                to: "/discounts",
              },
              {
                label: "Группы оплат",
                to: "/payment-groups",
              },
              {
                label: "Типы оплат",
                to: "/payment-types",
              },
              {
                label: "Типы сервисов",
                to: "/service-types",
              },
              {
                label: "Группы заказов",
                to: "/source-groups",
              },
              {
                label: "Заказы",
                to: "/sources",
              },
              {
                label: "Конфигурации синхронизаций",
                to: "/sync-confs",
              },
              {
                label: "Конфигурации логов",
                to: "/log-channel-telegram-confs",
              },
              {
                label: "Технические логи",
                to: "/technical-support-logs",
              },
            ],
          },
        ],
      }}
    >
      <Routes />
    </UIMain>
  )
}

export default App
