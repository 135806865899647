import * as Yup from "yup"

import {
  Denormalized,
  Dictionary,
  FiltersState,
  FTLDateUtils,
  requiredMessage,
} from "ftl-core"
import { OrderWaybill } from "../../types/orderWaybill"
import { FTLStatusTagProps } from "ftl-uikit"

export const initState: OrderWaybill = {
  nomenclatures: [],
  warehouseId: "",
  status: "DRAFT",
  name: "",
  phone: null,
  uuid: null,
  comment: null,
}

export const orderWaybillStatusLabels = {
  DRAFT: "Черновик",
  NEW: "Новая",
  SENT: "Отправлена",
  RECEIVED: "Принята",
}

export const orderWaybillStatusTagStatus: Dictionary<
  FTLStatusTagProps["status"]
> = {
  DRAFT: "error",
  NEW: "new",
  SENT: "success",
  RECEIVED: "success",
}

export const orderWaybillSchema = Yup.object({
  nomenclatures: Yup.array().required(requiredMessage),
  warehouseId: Yup.string().required(requiredMessage),
  status: Yup.string().required(requiredMessage),
  comment: Yup.string().notRequired(),
  name: Yup.string().required(requiredMessage),
  phone: Yup.string().notRequired(),
  uuid: Yup.string().notRequired(),
})

export const normalizeOrderWaybillFilters = (filters: FiltersState) => ({
  query: filters.query,
})

type normalizeFuncType = (
  { items }: Denormalized<OrderWaybill<"GET">>,
  context: {
    setData: React.Dispatch<OrderWaybill<"GET">[]>
    meta?: any
  }
) => OrderWaybill<"GET">[]

export const normalizeOrderWaybills: normalizeFuncType = (
  { items },
  context
) => {
  const normalizeDataOfTable = items.map((item) => {
    return {
      ...item,
      warehouseName: context.meta && context.meta[item.warehouseId],
      createdAt: FTLDateUtils.format(new Date(item?.createdAt ?? ""), {
        breakHours: false,
      }),
    }
  })
  return normalizeDataOfTable
}
