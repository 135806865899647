import { lazy } from "react"
import { Switch } from "react-router-dom"

import { FTLErrorPage } from "ftl-uikit"
import { CountryAPI, PictureAPI, ProfileAPI, UtilsAPI } from "./repository"
import { PrivateRoute } from "ftl-core"
import OrderWaybillPage from "./pages/OrderWaybill/List"
import DetailAttendancesPage from "./pages/Attendances/Detail"
import CurrenciesCreatePage from "./pages/AdditionalSettings/Currencies/CurrenciesCreacte/page/CurrenciesCreatePage";
import LogChannelTelegramConfCreatePage
  from "./pages/AdditionalSettings/LogChannelTelegramConf/LogChannelTelegramConfCreate/page/LogChannelTelegramConfCreatePage";

//Profile
const FTLProfilePage = lazy(() =>
  // Деструктурируем ftl-dashboards-templates чтобы достать FTLProfilePage
  import("ftl-dashboards-templates").then((module) => ({
    default: module.FTLProfilePage,
  }))
)

//Cities
const CitiesList = lazy(() => import("./pages/Cities/List"))
const CitiesDetailPage = lazy(() => import("./pages/Cities/Detail"))

//Warehouses
const WarehousesList = lazy(() => import("./pages/Warehouses/List"))
const WarehousesDetailPage = lazy(() => import("./pages/Warehouses/Detail"))

//StorageAddresses
const StorageAddressList = lazy(() => import("./pages/StorageAddresses/List"))
const StorageAddressDetailPage = lazy(
  () => import("./pages/StorageAddresses/Detail")
)

//OrgStructures
const OrgStructuresList = lazy(() => import("./pages/OrgStructures/List"))
const OrgStructuresDirectory = lazy(
  () => import("./pages/OrgStructureItems/Directory")
)
const OrgStructuresDetailPage = lazy(
  () => import("./pages/OrgStructures/Detail")
)

//LegalUnits
const LegalUnitsList = lazy(() => import("./pages/LegalUnits/List"))
const LegalUnitsDetailPage = lazy(() => import("./pages/LegalUnits/Detail"))

//Departments
const DepartmentsList = lazy(() => import("./pages/Departments/List"))
const DepartmentsDetailPage = lazy(() => import("./pages/Departments/Detail"))

//IikoSyncConf
const IikoSyncConfPage = lazy(() => import("./pages/IikoSyncConf/List"))
const DetailIikoSyncConfPage = lazy(() => import("./pages/IikoSyncConf/Detail"))

//IikoSyncTaskParam
const IikoSyncTaskParamPage = lazy(
  () => import("./pages/IikoSyncTaskParam/List")
)
const DetailIikoSyncTaskParamPage = lazy(
  () => import("./pages/IikoSyncTaskParam/Detail")
)

//IikoSyncTaskLog
const IikoSyncTaskLogPage = lazy(() => import("./pages/IikoSyncTaskLog/List"))
const DetailIikoSyncTaskLogPage = lazy(
  () => import("./pages/IikoSyncTaskLog/Detail")
)

//PointOfSale
const PointOfSalePage = lazy(() => import("./pages/PointOfSale/List"))
const DetailPointOfSalePage = lazy(() => import("./pages/PointOfSale/Detail"))

//IikoRequestLog
const IikoRequestLogPage = lazy(() => import("./pages/iikoRequestLog/List"))
const DetailIikoRequestLogPage = lazy(
  () => import("./pages/iikoRequestLog/Detail")
)

//User-waybills
const UserWaybillsPage = lazy(() => import("./pages/UserWaybills/List"))
const DetailUserWaybillPage = lazy(() => import("./pages/UserWaybills/Detail"))

//Currencies waybill templates
const OrderWaybillTemplatePage = lazy(
  () => import("./pages/OrderWaybillTemplate/List")
)
const DetailOrderWaybillTemplatePage = lazy(
  () => import("./pages/OrderWaybillTemplate/Detail")
)

//LocationPlanType
const LocationPlanTypePage = lazy(() => import("./pages/LocationPlanType/List"))
const DetailLocationPlanTypePage = lazy(
  () => import("./pages/LocationPlanType/Detail")
)

//CorpStructure
const CorpStructurePage = lazy(() => import("./pages/CorpStructure/List"))
const DetailCorpStructurePage = lazy(
  () => import("./pages/CorpStructure/Detail")
)

//LocationFormat
const LocationFormatPage = lazy(() => import("./pages/LocationFormat/List"))
const DetailLocationFormatPage = lazy(
  () => import("./pages/LocationFormat/Detail")
)

//Manager
const ManagerPage = lazy(() => import("./pages/Manager/List"))
const DetailManagerPage = lazy(() => import("./pages/Manager/Detail"))

const PriceCategory = lazy(() => import("./pages/PriceCategory/List"))
const DetailPriceCategoryPage = lazy(
  () => import("./pages/PriceCategory/Detail")
)

const AttendancePage = lazy(() => import("./pages/Attendances/List"))

const EmployeePage = lazy(() => import("./pages/Employees/List"))

const PositionPage = lazy(() => import("./pages/Positions/List"))

//Currencies
const CurrenciesListPage = lazy(() => import("./pages/AdditionalSettings/Currencies/CurrenciesList/page/CurrenciesListPage"))
const CurrenciesCreactePage = lazy(() => import("./pages/AdditionalSettings/Currencies/CurrenciesCreacte/page/CurrenciesCreatePage"))
const CurrenciesDetailPage = lazy(() => import("./pages/AdditionalSettings/Currencies/CurenciesDetail/page/CurrenciesDetailPage"))

//Delivery-types
const DeliveryTypesListPage = lazy(() => import("./pages/AdditionalSettings/DeliveryTypes/DeliveryTypesList/page/DeliveryTypesListPage"))
const DeliveryTypesCreatePage = lazy(() => import("./pages/AdditionalSettings/DeliveryTypes/DeliveryTypesCreate/page/DeliveryTypesCreatePage"))
const DeliveryTypesDetailPage = lazy(() => import("./pages/AdditionalSettings/DeliveryTypes/DeliveryTypesDetail/page/DeliveryTypesDetailPage"))

//Discounts
const DiscountsListPage = lazy(() => import("./pages/AdditionalSettings/Discounts/DiscountsList/page/DiscountsListPage"))
const DiscountsDetailPage = lazy(() => import("./pages/AdditionalSettings/Discounts/DiscountsDetail/page/DiscountsDetailPage"))

//Payment groups
const PaymentGroupsListPage = lazy(() => import("./pages/AdditionalSettings/PaymentGroups/PaymentGroupList/page/PaymentGroupsListPage"))
const PaymentGroupsCreatePage = lazy(() => import("./pages/AdditionalSettings/PaymentGroups/PaymentGroupsCreate/page/PaymentGroupsCreatePage"))
const PaymentGroupsDetailPage = lazy(() => import("./pages/AdditionalSettings/PaymentGroups/PaymentGroupsDetail/page/PaymentGroupsDetailPage"))

//Payment Types
const PaymentTypesListPage = lazy(() => import("./pages/AdditionalSettings/PaymentTypes/PaymentTypesList/page/PaymentTypesListPage"))
const PaymentTypesCreatePage = lazy(() => import("./pages/AdditionalSettings/PaymentTypes/PaymentTypesCreate/page/PaymentTypesCreatePage"))
const PaymentTypesDetailPage = lazy(() => import("./pages/AdditionalSettings/PaymentTypes/PaymentTypesDetail/page/PaymentTypesDetailPage"))

//Service types
const ServiceTypesListPage = lazy(() => import("./pages/AdditionalSettings/ServiceTypes/ServiceTypesList/page/ServiceTypesListPage"))
const ServiceTypesCreatePage = lazy(() => import("./pages/AdditionalSettings/ServiceTypes/ServiceTypesCreate/page/ServiceTypesCreatePage"))
const ServiceTypesDetailPage = lazy(() => import("./pages/AdditionalSettings/ServiceTypes/ServiceTypesDetail/page/ServiceTypesDetailPage"))

//Source groups
const SourceGroupsListPage = lazy(() => import("./pages/AdditionalSettings/SourceGroups/SourceGroupsList/page/SourceGroupsListPage"))
const SourceGroupsCreatePage = lazy(() => import("./pages/AdditionalSettings/SourceGroups/SourceGroupsCreate/page/SourceGroupsCreatePage"))
const SourceGroupsDetailPage = lazy(() => import("./pages/AdditionalSettings/SourceGroups/SourceGroupsDetail/page/SourceGroupsDetailPage"))

//SyncConfs
const SyncConfsListPage = lazy(() => import("./pages/AdditionalSettings/SyncConfs/SyncConfsList/page/SyncConfsListPage"))
const SyncConfsDetailPage = lazy(() => import("./pages/AdditionalSettings/SyncConfs/SyncConfsDetail/page/SyncConfsDetailPage"))

//LogChannelTelegramConfs
const LogChannelTelegramConfsListPage = lazy(() => import("./pages/AdditionalSettings/LogChannelTelegramConf/LogChannelTelegramConfsList/page/LogChannelTelegramConfListPage"))
const LogChannelTelegramConfsCreatePage = lazy(() => import("./pages/AdditionalSettings/LogChannelTelegramConf/LogChannelTelegramConfCreate/page/LogChannelTelegramConfCreatePage"))
const LogChannelTelegramConfsDetailPage = lazy(() => import("./pages/AdditionalSettings/LogChannelTelegramConf/LogChannelTelegramConfDetail/page/LogChannelTelegramConfDetailPage"))

//TechnicalSupportLogs
const TechnicalSupportLogsListPage = lazy(() => import("./pages/AdditionalSettings/TechnicalSupportLog/TechnicalSupportLogList/page/TechnicalSupportLogListPage"))
const TechnicalSupportLogsDetailPage = lazy(() => import("./pages/AdditionalSettings/TechnicalSupportLog/TechnicalSupportLogDetail/page/TechnicalSupportLogDetailPage"))
const TechnicalSupportLogsDeletePage = lazy(() => import("./pages/AdditionalSettings/TechnicalSupportLog/TechnicalSupportLogDelete/page/TechnicalSupportLogDeletePage"))

//Sources
const SourcesListPage = lazy(() => import("./pages/AdditionalSettings/Sources/SourcesList/page/SourcesListPage"))
const SourcesCreatePage = lazy(() => import("./pages/AdditionalSettings/Sources/SourcesCreate/page/SourcesCreatePage"))
const SourcesDetailPage = lazy(() => import("./pages/AdditionalSettings/Sources/SourcesDetail/page/SourcesDetailPage"))

//Nomenclatures
const NomenclaturesListPage = lazy(() => import("./pages/Nomenclatures/NomenclaturesList/page/NomenclaturesListPage"))
const NomenclaturesDetailPage = lazy(() => import("./pages/Nomenclatures/NomenclaturesDetail/page/NomenclaturesDetailPage"))

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/profile">
        <FTLProfilePage
          getProfile={ProfileAPI.get}
          editProfile={ProfileAPI.edit}
          getCountry={CountryAPI.getById}
          getCountries={CountryAPI.getList}
          getDefaultCountry={() => UtilsAPI.getDefault("countries")}
          deleteProfilePicture={ProfileAPI.deletePicture}
          changeProfilePassword={ProfileAPI.changePassword}
          postPicture={PictureAPI.post}
        />
      </PrivateRoute>

      <PrivateRoute exact path="/cities">
        <CitiesList />
      </PrivateRoute>
      <PrivateRoute exact path="/cities/new">
        <CitiesDetailPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/cities/:id">
        <CitiesDetailPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/warehouses">
        <WarehousesList />
      </PrivateRoute>
      <PrivateRoute exact path="/warehouses/new">
        <WarehousesDetailPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/warehouses/:id">
        <WarehousesDetailPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/storage-addresses">
        <StorageAddressList />
      </PrivateRoute>
      <PrivateRoute exact path="/storage-addresses/new">
        <StorageAddressDetailPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/storage-addresses/:id">
        <StorageAddressDetailPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/org-structures">
        <OrgStructuresList />
      </PrivateRoute>
      <PrivateRoute exact path="/org-structures/:id/inner-items">
        <OrgStructuresDirectory />
      </PrivateRoute>
      <PrivateRoute exact path="/org-structures/new">
        <OrgStructuresDetailPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/org-structures/:id">
        <OrgStructuresDetailPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/legal-units">
        <LegalUnitsList />
      </PrivateRoute>
      <PrivateRoute exact path="/legal-units/new">
        <LegalUnitsDetailPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/legal-units/:id">
        <LegalUnitsDetailPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/departments">
        <DepartmentsList />
      </PrivateRoute>
      <PrivateRoute exact path="/departments/new">
        <DepartmentsDetailPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/departments/:id">
        <DepartmentsDetailPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/iiko-sync-confs">
        <IikoSyncConfPage />
      </PrivateRoute>
      <PrivateRoute exact path="/iiko-sync-confs/new">
        <DetailIikoSyncConfPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/iiko-sync-confs/:id">
        <DetailIikoSyncConfPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/iiko-sync-task-params">
        <IikoSyncTaskParamPage />
      </PrivateRoute>
      <PrivateRoute exact path="/iiko-sync-task-params/new">
        <DetailIikoSyncTaskParamPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/iiko-sync-task-params/:id">
        <DetailIikoSyncTaskParamPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/iiko-sync-task-logs">
        <IikoSyncTaskLogPage />
      </PrivateRoute>
      <PrivateRoute exact path="/iiko-sync-task-logs/new">
        <DetailIikoSyncTaskLogPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/iiko-sync-task-logs/:id">
        <DetailIikoSyncTaskLogPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/iiko-request-logs">
        <IikoRequestLogPage />
      </PrivateRoute>
      <PrivateRoute exact path="/iiko-request-logs/new">
        <DetailIikoRequestLogPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/iiko-request-logs/:id">
        <DetailIikoRequestLogPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/points-of-sale">
        <PointOfSalePage />
      </PrivateRoute>
      <PrivateRoute exact path="/points-of-sale/new">
        <DetailPointOfSalePage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/points-of-sale/:id">
        <DetailPointOfSalePage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/user-waybills">
        <UserWaybillsPage />
      </PrivateRoute>
      <PrivateRoute exact path="/user-waybills/new">
        <DetailUserWaybillPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/user-waybills/:id">
        <DetailUserWaybillPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/order-waybill-templates">
        <OrderWaybillTemplatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/order-waybill-templates/new">
        <DetailOrderWaybillTemplatePage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/order-waybill-templates/:id">
        <DetailOrderWaybillTemplatePage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/order-waybills">
        <OrderWaybillPage />
      </PrivateRoute>

      <PrivateRoute exact path="/location-plan-types">
        <LocationPlanTypePage />
      </PrivateRoute>
      <PrivateRoute exact path="/location-plan-types/new">
        <DetailLocationPlanTypePage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/location-plan-types/:id">
        <DetailLocationPlanTypePage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/corp-structures">
        <CorpStructurePage />
      </PrivateRoute>
      <PrivateRoute exact path="/corp-structures/new">
        <DetailCorpStructurePage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/corp-structures/:id">
        <DetailCorpStructurePage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/location-formats">
        <LocationFormatPage />
      </PrivateRoute>
      <PrivateRoute exact path="/location-formats/new">
        <DetailLocationFormatPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/location-formats/:id">
        <DetailLocationFormatPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/managers">
        <ManagerPage />
      </PrivateRoute>
      <PrivateRoute exact path="/managers/new">
        <DetailManagerPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/managers/:id">
        <DetailManagerPage pageType="edit" />
      </PrivateRoute>
      <PrivateRoute exact path="/managers/:id/departments/new"></PrivateRoute>
      <PrivateRoute
        exact
        path="/managers/:id/departments/:propertyId"
      ></PrivateRoute>

      <PrivateRoute exact path="/price-categories">
        <PriceCategory />
      </PrivateRoute>
      <PrivateRoute exact path="/price-categories/new">
        <DetailPriceCategoryPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/price-categories/:id">
        <DetailPriceCategoryPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/attendances">
        <AttendancePage />
      </PrivateRoute>
      <PrivateRoute exact path="/attendances/new">
        <DetailAttendancesPage pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/attendances/:id">
        <DetailAttendancesPage pageType="edit" />
      </PrivateRoute>

      <PrivateRoute exact path="/employees">
        <EmployeePage />
      </PrivateRoute>
      {/* <PrivateRoute exact path="/employees/new">
        < pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/employees/:id">
        < pageType="edit" />
      </PrivateRoute> */}

      <PrivateRoute exact path="/positions">
        <PositionPage />
      </PrivateRoute>

      <PrivateRoute exact path="/Currencies">
        <CurrenciesListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/Currencies/new">
        <CurrenciesCreatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/Currencies/:id">
        <CurrenciesDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/delivery-types">
        <DeliveryTypesListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/delivery-types/new">
        <DeliveryTypesCreatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/delivery-types/:id">
        <DeliveryTypesDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/discounts">
        <DiscountsListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/discounts/:id">
        <DiscountsDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/payment-groups">
        <PaymentGroupsListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/payment-groups/new">
        <PaymentGroupsCreatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/payment-groups/:id">
        <PaymentGroupsDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/payment-types">
        <PaymentTypesListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/payment-types/new">
      <PaymentTypesCreatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/payment-types/:id">
        <PaymentTypesDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/service-types">
        <ServiceTypesListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/service-types/new">
        <ServiceTypesCreatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/service-types/:id">
        <ServiceTypesDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/source-groups">
        <SourceGroupsListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/source-groups/new">
        <SourceGroupsCreatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/source-groups/:id">
        <SourceGroupsDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/sync-confs">
        <SyncConfsListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/sync-confs/:id">
        <SyncConfsDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/log-channel-telegram-confs">
        <LogChannelTelegramConfsListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/log-channel-telegram-confs/new">
        <LogChannelTelegramConfCreatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/log-channel-telegram-confs/:id">
        <LogChannelTelegramConfsDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/technical-support-logs">
        <TechnicalSupportLogsListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/technical-support-logs/delete">
        <TechnicalSupportLogsDeletePage />
      </PrivateRoute>
      <PrivateRoute exact path="/technical-support-logs/:id">
        <TechnicalSupportLogsDetailPage />
      </PrivateRoute>


      <PrivateRoute exact path="/sources">
        <SourcesListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/sources/new">
        <SourcesCreatePage />
      </PrivateRoute>
      <PrivateRoute exact path="/sources/:id">
        <SourcesDetailPage />
      </PrivateRoute>

      <PrivateRoute exact path="/nomenclatures">
        <NomenclaturesListPage />
      </PrivateRoute>
      <PrivateRoute exact path="/nomenclatures/:id">
        <NomenclaturesDetailPage />
      </PrivateRoute>

      {/* <PrivateRoute exact path="/positions/new">
        < pageType="new" />
      </PrivateRoute>
      <PrivateRoute exact path="/positions/:id">
        < pageType="edit" />
      </PrivateRoute> */}

      <PrivateRoute exact path={["*", "/404"]}>
        <FTLErrorPage
          title="Страницы не существует"
          message="Наверное, вы перешли по неправильной ссылке."
        />
      </PrivateRoute>
    </Switch>
  )
}
