import { FTLSmartCreateDomainStore } from "ftl-dashboards-templates";
import LogChannelTelegramConfCreateScheme from "../entity/LogChannelTelegramConfCreateScheme";
import LogChannelTelegramConfCreateApiRepository
  from "../../../../../infrastructure/api/AdditionalSettings/LogChannelTelegramConf/LogChannelTelegramConfCreateApiRepository";
import LogChannelTelegramConfCreateEntity from "../entity/LogChannelTelegramConfCreate";


const LogChannelTelegramConfCreateStore = FTLSmartCreateDomainStore<typeof LogChannelTelegramConfCreateScheme, LogChannelTelegramConfCreateEntity>({
  schema: LogChannelTelegramConfCreateScheme,
  repository: LogChannelTelegramConfCreateApiRepository
})

export default LogChannelTelegramConfCreateStore