import { Box } from "@material-ui/core"
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { DetailPageProps, useDetailPage, useTabs } from "ftl-core"
import { FTLDetailPage } from "ftl-dashboards-templates"
import { FTLBreadcrumbs, FTLLoader, FTLPageHeader, FTLSelect, FTLToolbar } from "ftl-uikit"
import { Fragment, useState } from "react"
import { AttendanceTemplate } from "../../types/attendance"
import { attendanceSchema, initState } from "./Model"
import AttendanceDetailStyles from "./Styles"
import moment from "moment"
import MomentUtils from "@date-io/moment"

const DetailAttendancesPage = ({ pageType }: DetailPageProps) => {
    
    const classes = AttendanceDetailStyles()

    const [StartDate, setStartDate] = useState(moment());
    const [EndDate, setEndDate] = useState(moment());
    const [inpuStartValue, setInputStartValue] = useState(moment().format("DD:MM:YYYY HH:mm"));
    const [inputEndValue, setInputEndValue] = useState(moment().format("DD:MM:YYYY HH:mm"));

    const onStartDateChange = (date: any, value: any) => {
        setStartDate(date);
        setInputStartValue(value);
    };

    const onEndDateChange = (date: any, value: any) => {
        setEndDate(date);
        setInputEndValue(value);
    };

    const dateFormatter = (str: string) => {
        return str;
    };

    console.log(StartDate.toString());


    const {
        isFetching,
        formik,
        history,
        isFormNotChanged,
        setIsFetching,
        setEntityData,
        entityData,
    } = useDetailPage({
        pageType,
        initialValues: initState,
        validationSchema: attendanceSchema,

        onSubmit: async () => {

        }
    })

    return (

        <FTLDetailPage<AttendanceTemplate<"FORMIK">>
            formik={formik}
            headerComponent={
                <>
                    <FTLBreadcrumbs
                        items={[
                            { label: "Явки", to: "/attendances" },
                            {
                                label:
                                    pageType === "edit"
                                        ? "Редактирование явки"
                                        : "Новая явка",
                                to: "#",
                            },
                        ]}
                    />

                    <FTLPageHeader title={
                        pageType === "edit"
                            ? "Редактирование явки"
                            : "Новая явка"
                    } BoxProps={{ mb: 6 }} />
                </>

            }
            toolbarComponent={

                <FTLToolbar
                    position="sticky"
                    onSaveBtnId="form"
                    onCancel={() => history.push("/attendances")}
                    async={formik.isSubmitting}
                    disabled={isFormNotChanged}
                    onSaveMessage={pageType === "edit" ? "Сохранить" : "Создать"}
                />
            }
        >



            {isFetching ? (
                <FTLLoader height="50vh" />
            ) : (
                <form
                    noValidate
                    id="form"
                    onSubmit={(e) => {
                        e.preventDefault()
                        formik.handleSubmit()
                    }}
                >
                    <Box display="grid" mt={6} gridGap="24px">
                        <Box width="360px">
                            <FTLSelect
                                label="Сотрудник"
                                placeholder="Выберите сотрудника"
                                multiple
                            />
                        </Box>
                        <Box width="360px">
                            <FTLSelect
                                label="Должность"
                                placeholder="Должность"
                                multiple
                            />
                        </Box>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <Box width="360px">
                                <KeyboardDateTimePicker
                                    label="Дата и время начала"
                                    showTodayButton={true}
                                    value={StartDate}
                                    format="DD:MM:YYYY HH:mm"
                                    inputValue={inpuStartValue}
                                    onChange={onStartDateChange}
                                    rifmFormatter={dateFormatter}
                                />
                            </Box>
                            <Box width="360px">
                                <KeyboardDateTimePicker
                                    label="Дата и время начала"
                                    showTodayButton={true}
                                    value={EndDate}
                                    format="DD:MM:YYYY HH:mm"
                                    inputValue={inputEndValue}
                                    onChange={onEndDateChange}
                                    rifmFormatter={dateFormatter}
                                />
                            </Box>
                        </MuiPickersUtilsProvider>
                        <Box width="360px">
                            <FTLSelect
                                label="Статус"
                                placeholder="Статус"
                                multiple
                            />
                        </Box>
                    </Box>

                </form>
            )}
            

        </FTLDetailPage >




    )
}

export default DetailAttendancesPage