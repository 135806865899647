import SmartCreateRepository
  from "ftl-dashboards-templates/dist/domains/FTLSmartCreateDomain/repositories/SmartCreateRepository";
import { BasicResponse } from "ftl-core";
import BaseApiRepository from "../../base/BaseApiRepository";
import { API_BASE_URL } from "ftl-dashboards-templates/dist/infrastructure/api/auth/routers/AuthRouter";
import LogChannelTelegramConfCreateEntity
  from "../../../../domains/AdditionalSettings/LogChannelTelegramConf/LogChannelTelegramConfCreate/entity/LogChannelTelegramConfCreate";

const LogChannelTelegramConfCreateApiRepository: SmartCreateRepository<LogChannelTelegramConfCreateEntity> = {
  create: (entity: LogChannelTelegramConfCreateEntity) => {
    return new Promise<BasicResponse<LogChannelTelegramConfCreateEntity>>(async (resolve) => {
      const payload = {
        chatId: entity.chatId,
        title: entity.title,
        key: entity.key,
        types: entity.types.map((item:any) => item.value)
      }
      const result = await BaseApiRepository.post<BasicResponse<LogChannelTelegramConfCreateEntity>>(`${API_BASE_URL}/api/v1/log-channel-telegram-confs`, payload)
      resolve ({
        result: result.data.result
      })
    })
  }
}

export default LogChannelTelegramConfCreateApiRepository