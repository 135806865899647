import LogChannelTelegramConfCreateProps from "../props/LogChannelTelegramConfCreateProps";
import LogChannelTelegramConfCreateStyles from "../styles/LogChannelTelegramConfCreateStyles";
import { useGate, useStore } from "effector-react";
import { history } from "ftl-core";
import {
  FTLBreadcrumbs,
  FTLPageHeader,
  FTLSelect,
  FTLTextField,
  FTLToolbar,
} from "ftl-uikit";
import { Box, LinearProgress } from "@material-ui/core";
import LogChannelTelegramConfCreateStore from "../../../../../domains/AdditionalSettings/LogChannelTelegramConf/LogChannelTelegramConfCreate/store/LogChannelTelegramConfCreateStore";
import LogChannelTelegramConfCreateEntity from "../../../../../domains/AdditionalSettings/LogChannelTelegramConf/LogChannelTelegramConfCreate/entity/LogChannelTelegramConfCreate";

const breadCrumps = [
  { label: "Конфигурации логов", to: "/log-channel-telegram-confs" },
  { label: "Создание", to: "#" },
];

const LogChannelTelegramConfCreatePage = (
  props: LogChannelTelegramConfCreateProps
) => {
  const classes = LogChannelTelegramConfCreateStyles();
  const store = LogChannelTelegramConfCreateStore;
  useGate(store.gate);
  const formErrors = useStore(store.store.errors);
  const formStore: LogChannelTelegramConfCreateEntity = useStore(
    store.store.values
  );
  const formIsValid = useStore(store.store.isValid);
  const formMeta = useStore(store.meta);
  console.log(formStore);
  store.didSendData.watch((payload) => {
    store.store.fill(store.store.values.defaultState)
    history.push(`/log-channel-telegram-confs`);
  });

  return (
    <div className={classes.root}>
      <Box marginBottom={"auto"}>
        <FTLBreadcrumbs items={breadCrumps} />
        {formMeta.isLoad ? <LinearProgress color={"secondary"} /> : null}

        <FTLPageHeader
          title={"Создание конфигурации логов"}
          BoxProps={{ mb: 6 }}
        />
        <form
          noValidate
          id={"log-channel-telegram-confs-create-form"}
          onSubmit={(e) => {
            e.preventDefault();
            store.store.submit();
          }}
        >
          <Box display={"grid"} mt={6} gridGap={"24px"} width={"360px"}>
            <FTLTextField
              required
              name={"title"}
              label={"Название"}
              value={formStore.title}
              onChange={(e) => store.store.fields.title.set(e.target.value)}
              error={formErrors.title && formMeta.showErrors ? true : false}
              helperText={formErrors.title}
              fullWidth
              placeholder={"Введите название"}
            />
          </Box>
          <Box display={"grid"} mt={6} gridGap={"24px"} width={"360px"}>
            <FTLTextField
              required
              name={"description"}
              label={"Ключ"}
              value={formStore.key}
              onChange={(e) => store.store.fields.key.set(e.target.value)}
              error={formErrors.key && formMeta.showErrors ? true : false}
              helperText={formErrors.key}
              fullWidth
              placeholder={"Введите ключ"}
            />
          </Box>
          <Box display={"grid"} mt={6} gridGap={"24px"} width={"360px"}>
            <FTLTextField
              required
              name={"description"}
              label={"id чата"}
              value={formStore.chatId}
              onChange={(e) => store.store.fields.chatId.set(e.target.value)}
              error={formErrors.chatId && formMeta.showErrors ? true : false}
              helperText={formErrors.chatId}
              fullWidth
              placeholder={"Введите id чата"}
            />
          </Box>
          <Box display={"grid"} mt={6} gridGap={"24px"} width={"360px"}>
            <FTLSelect
              required
              fullWidth
              label={"Тип"}
              multiple={true}
              options={[
                {
                  value: "ERROR",
                  label: "ERROR",
                },
                { value: "INFO", label: "INFO" },
                { value: "WARNING", label: "WARNING" },
              ]}

              placeholder={"Введите тип"}
              value={formStore.types}
              onChange={(option) => option ? store.store.fields.types.set(option as []) : store.store.fields.types.set([])}

            />
          </Box>
        </form>
      </Box>
      <FTLToolbar
        position={"sticky"}
        onSaveBtnId={"form"}
        disabled={formIsValid == false || formMeta.isLoad == true}
        onSaveMessage="Сохранить"
        onCancel={() => history.goBack()}
        showSaveButton={true}
        onSave={() => {
          store.store.submit();
        }}
      />
    </div>
  );
};

export default LogChannelTelegramConfCreatePage;
