import { AxiosResponse } from "axios"

//types
import {
  BasicResponse,
  createCountryRepository,
  createEntityRepository,
  createProfileRepository,
  createRepositoryInstance,
  createUtilsRepository,
  ListResponse,
} from "ftl-core"
import { City, CityRequest } from "../types/city"
import { Department, DepartmentRequest, ManagerForDepType } from "../types/department"
import { LegalUnit, LegalUnitRequest } from "../types/legalUnit"
import { OrgStructure, OrgStructureRequest } from "../types/orgStructure"
import { PriceCategory, PriceCategoryRequest } from "../types/priceCategory"
import { StorageAddress, StorageAddressRequest } from "../types/storageAddress"
import { Warehouse, WarehouseRequest } from "../types/warehouse"
import { IikoSyncConf, IikoSyncConfRequest } from "../types/iikoSyncConf"
import {
  IikoSyncTaskParam,
  IikoSyncTaskParamRequest,
} from "../types/iikoSyncTaskParam"
import {
  IikoSyncTaskLog,
  IikoSyncTaskLogRequest,
} from "../types/iikoSyncTaskLog"
import { IikoRequestLog, IikoRequestLogRequest } from "../types/iikoRequestLog"
import { PointOfSale, PointOfSaleRequest } from "../types/pointOfSale"
import {
  OrderWaybillTemplate,
  OrderWaybillTemplateRequest,
} from "../types/orderWaybillTemplate"
import {
  LocationPlanType,
  LocationPlanTypeRequest,
} from "../types/locationPlanType"
import { UserWaybill, UserWaybillRequest } from "../types/userWaybill"
import { OrderWaybill, OrderWaybillRequest } from "../types/orderWaybill"
import { CorpStructure, CorpStructureRequest } from "../types/corpStructure"
import { AuthClient } from "ftl-core"
import { Nomenclature, NomenclatureRequest } from "../types/nomenclature"
import { LocationFormat, LocationFormatRequest } from "../types/locationFormat"
import { Manager, ManagerRequest } from "../types/manager"
import { Attendance, AttendanceRequest } from "../types/attendance"
import { Employee, EmployeeRequest } from "../types/employee"
import { Position, PositionRequest } from "../types/position"

enum Versions {
  v1 = "v1",
}

export const authApiBaseUrl = process.env.REACT_APP_FTL_AUTH_BASE_URL
  ? process.env.REACT_APP_FTL_AUTH_BASE_URL
  : "https://consumers.chain.first.ftl-dev.ru"
export const apiBaseUrl = process.env.REACT_APP_FTL_CRM_BASE_URL
  ? process.env.REACT_APP_FTL_CRM_BASE_URL
  // : "http://localhost:42069"
  : "https://consumers.chain.first.ftl-dev.ru"

export const baseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? `${apiBaseUrl}/api/${Versions.v1}`
    : `${apiBaseUrl}/api/${Versions.v1}`

export const HRbaseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? `https://consumers.hr.first.ftl-dev.ru/api/${Versions.v1}`
    : `https://consumers.hr.first.ftl-dev.ru/api/${Versions.v1}`

export const repository = createRepositoryInstance({
  baseUrl,
  authErrors: {
    reasonKey: "reason",
    tokenExpired: ["ACCESS_TOKEN_EXPIRED"],
    permissionInsufficient: ["CREDENTIAL_PERMISSION_INSUFFICIENT"],
    needToLogin: [
      "CREDENTIAL_BLOCKED",
      "ACCESS_TOKEN_INACTIVATED",
      "ACCESS_TOKEN_NOT_EXIST",
      "ACCESS_TOKEN_REFRESHED",
      "ACCESS_TOKEN_MODIFIED",
      "ACCESS_TOKEN_MALFORMED",
    ],
  },
  tokenRefresh: () => AuthAPI.refresh(),
})

export const AuthAPI = new AuthClient(
  repository,
  {
    signIn: `${authApiBaseUrl}/not-secure/api/v1/auth/login/email`,
    signOut: `${authApiBaseUrl}/not-secure/api/v1/auth/logout`,
    refresh: `${authApiBaseUrl}/not-secure/api/v1/auth/refresh`,
  },
  {
    loginPropName: "email",
  }
)

export const UtilsAPI = createUtilsRepository(repository)
export const HRUtilsAPI = createUtilsRepository(repository)

//Profile

export const ProfileAPI = createProfileRepository(repository)

//Country

export const CountryAPI = createCountryRepository(repository, UtilsAPI)

//Picture

export const PictureAPI = Object.freeze({
  post: (request: FormData): Promise<AxiosResponse<{ result: string }>> => {
    return repository.post(`pictures`, request)
  },
})

//Cities
const normalizeCityPayload = (payload: City): City => ({
  name: payload.name,
  code: payload.code,
  point: payload.point,
})

export const CityAPI = Object.freeze({
  ...createEntityRepository<CityRequest, City<"GET">>({
    repositoryInstance: repository,
    basePath: "cities",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeCityPayload,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/cities/" + values.id, values),
  delete: (id: string) => repository.delete(baseUrl + "/cities/" + id),
})

//Warehouses
const normalizeWarehousePayload = (
  payload: Warehouse<"POST"> | Warehouse<"PATCH">
): Warehouse => ({
  name: payload.name,
  photoId: payload.photoId,
  orgStructureId: payload.orgStructureId,
})

export const WarehouseAPI = Object.freeze({
  ...createEntityRepository<
    WarehouseRequest,
    Warehouse<"GET">,
    Warehouse,
    Warehouse<"PATCH">
  >({
    repositoryInstance: repository,
    basePath: "warehouses",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeWarehousePayload,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/warehouses/" + values.id, values),
  delete: (id: string) => repository.delete(baseUrl + "/warehouses/" + id),
})

//OrgStructure
const normalizeOrgStructurePayload = (
  payload: OrgStructure<"POST"> | OrgStructure<"PATCH">
): OrgStructure => ({
  title: payload.title,
  parentId: payload.parentId,
})

export const OrgStructureAPI = Object.freeze({
  ...createEntityRepository<OrgStructureRequest, OrgStructure<"GET">>({
    repositoryInstance: repository,
    basePath: "org-structures",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeOrgStructurePayload,
  }),
  delete: (id: string) => repository.delete(baseUrl + "/org-structures/" + id),
  edit: (values: any) =>
    repository.put(baseUrl + "/org-structures/" + values.id, values),
})

//Department
const normalizeDepartmentPayload = (
  payload: Department<"POST"> | Department<"PATCH">
): Department => ({
  title: payload.title,
  orgStructureId: payload.orgStructureId,
  warehouseId: payload.warehouseId,
  cityId: payload.cityId,
  priceCategoryId: payload.priceCategoryId,
  status: payload.status,
  legalUnitId: payload.legalUnitId,
  point: payload.point,
  code: payload.code,
  corpStructureId: payload.corpStructureId,
  locationPlans: payload.locationPlans,
  startWorkDate: payload.startWorkDate,
})

export const DepartmentAPI = Object.freeze({
  ...createEntityRepository<DepartmentRequest, Department<"GET">>({
    repositoryInstance: repository,
    basePath: "departments",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeDepartmentPayload,
  }),
  getAllList: (
    params: DepartmentRequest
  ): Promise<AxiosResponse<ListResponse<Department<"GET">>>> => {
    return UtilsAPI.getWithParams(`departments`, params)
  },
  delete: (id: string) => repository.delete(baseUrl + "/departments/" + id),
  edit: (values: any) =>
    repository.put(baseUrl + "/departments/" + values.id, values),
})

//StorageAddress
const normalizeStorageAddressPayload = (
  payload: StorageAddress<"POST"> | StorageAddress<"PATCH">
): StorageAddress => ({
  title: payload.title,
  warehouseId: payload.warehouseId,
  code: payload.code,
  photoId: payload.photoId,
})

export const StorageAddressAPI = Object.freeze({
  ...createEntityRepository<StorageAddressRequest, StorageAddress<"GET">>({
    repositoryInstance: repository,
    basePath: "storage-addresses",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeStorageAddressPayload,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/storage-addresses/" + values.id, values),
  delete: (id: string) =>
    repository.delete(baseUrl + "/storage-addresses/" + id),
})

//LegalUnit
const normalizeLegalUnitsPayload = (
  payload: LegalUnit<"POST"> | LegalUnit<"PATCH">
): LegalUnit => ({
  title: payload.title,
  shortLegalName: payload.shortLegalName,
  fullLegalName: payload.fullLegalName,
})

export const LegalUnitAPI = Object.freeze({
  ...createEntityRepository<LegalUnitRequest, LegalUnit<"GET">>({
    repositoryInstance: repository,
    basePath: "legal-units",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeLegalUnitsPayload,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/legal-units/" + values.id, values),
  delete: (id: string) => repository.delete(baseUrl + "/legal-units/" + id),
})

//PriceCategory
const normalizePriceCategoriesPayload = (
  payload: PriceCategory<"POST"> | PriceCategory<"PATCH">
): PriceCategory => ({
  id: payload.id,
  title: payload.title,
  currency: payload.currency,
  code: payload.code,
})

export const PriceCategoryAPI = Object.freeze({
  ...createEntityRepository<PriceCategoryRequest, PriceCategory<"GET">>({
    repositoryInstance: repository,
    basePath: "price-categories",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizePriceCategoriesPayload,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/price-categories/" + values.id, values),
  delete: (id: string) =>
    repository.delete(baseUrl + "/price-categories/" + id),
  getPriceCategoryValues: (priceCategoryId: string) =>
    repository.get(baseUrl + "/price-category-values/" + priceCategoryId),
})

// iikoSyncConf
const normalizeIikoSyncConfPayload = (
  payload: IikoSyncConf<"POST"> | IikoSyncConf<"PATCH">
): IikoSyncConf => ({
  name: payload.name,
  iikoVersion: payload.iikoVersion,
  id: payload.id,
  url: payload.url,
  login: payload.login,
  password: payload.password,
  organizationId: payload.organizationId,
  token: payload.token,
  type: payload.type,
  syncObjectList: payload.syncObjectList,
  synchronizationDirection: payload.synchronizationDirection,
  createdAt: payload.createdAt,
})

export const IikoSyncConfAPI = Object.freeze({
  ...createEntityRepository<IikoSyncConfRequest, IikoSyncConf<"GET">>({
    repositoryInstance: repository,
    basePath: "iiko-sync-confs",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeIikoSyncConfPayload,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/iiko-sync-confs-list/" + values.id, values),
  delete: (id: string) => repository.delete(baseUrl + "/iiko-sync-confs/" + id),
  create: (values: any) =>
    repository.post(baseUrl + "/iiko-sync-confs-list/" + values.id, values),
})

//IikoSyncTaskParam
const normalizeIikoSyncTaskParamPayload = (
  payload: IikoSyncTaskParam<"POST"> | IikoSyncTaskParam<"PATCH">
): IikoSyncTaskParam => ({
  id: payload.id,
  iikoSyncConfId: payload.iikoSyncConfId,
  title: payload.title,
  description: payload.description,
  period: payload.period,
  createdAt: payload.createdAt,
})

export const IikoSyncTaskParamAPI = Object.freeze({
  ...createEntityRepository<IikoSyncTaskParamRequest, IikoSyncTaskParam<"GET">>(
    {
      repositoryInstance: repository,
      basePath: "iiko-sync-task-params",
      utils: UtilsAPI,
      normalizeEntityPayload: normalizeIikoSyncTaskParamPayload,
    }
  ),
  edit: (values: any) =>
    repository.put(baseUrl + "/iiko-sync-task-params/" + values.id, values),
  delete: (id: string) =>
    repository.delete(baseUrl + "/iiko-sync-task-params/" + id),
})

//iikoSyncTaskLog
const normalizeIikoSyncTaskLogPayload = (
  payload: IikoSyncTaskLog<"POST"> | IikoSyncTaskLog<"PATCH">
): IikoSyncTaskLog => ({
  id: payload.id,
  iikoSyncConfId: payload.iikoSyncConfId,
  iikoSyncTaskParamId: payload.iikoSyncTaskParamId,
  startTime: payload.startTime,
  endTime: payload.endTime,
  createdAt: payload.createdAt,
})

export const IikoSyncTaskLogAPI = createEntityRepository<
  IikoSyncTaskLogRequest,
  IikoSyncTaskLog<"GET">
>({
  repositoryInstance: repository,
  basePath: "iiko-sync-task-logs",
  utils: UtilsAPI,
  normalizeEntityPayload: normalizeIikoSyncTaskLogPayload,
})

//iikoRequestLog
const normalizeIikoRequestLogPayload = (
  payload: IikoRequestLog<"POST"> | IikoRequestLog<"PATCH">
): IikoRequestLog => ({
  id: payload.id,
  iikoSyncConfId: payload.iikoSyncConfId,
  iikoSyncTaskParamId: payload.iikoSyncTaskParamId,
  iikoSyncTaskLogId: payload.iikoSyncTaskLogId,
  requestType: payload.requestType,
  status: payload.status,
  request: payload.request,
  response: payload.response,
  createdAt: payload.createdAt,
})

export const IikoRequestLogAPI = createEntityRepository<
  IikoRequestLogRequest,
  IikoRequestLog<"GET">
>({
  repositoryInstance: repository,
  basePath: "iiko-request-logs",
  utils: UtilsAPI,
  normalizeEntityPayload: normalizeIikoRequestLogPayload,
})

//PointOfSale
const normalizePointOfSalePayload = (payload: PointOfSale) => ({
  title: payload.title,
  departmentId: payload.departmentId,
  legalUnitId: payload.legalUnitId,
  status: payload.status,
  type: payload.type,
  createdAt: payload.createdAt,
  deviceName: payload.deviceName
})

export const PointOfSaleAPI = Object.freeze({
  ...createEntityRepository<
    PointOfSaleRequest,
    PointOfSale<"GET">,
    PointOfSale,
    PointOfSale<"PATCH">
  >({
    repositoryInstance: repository,
    basePath: "points-of-sale",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizePointOfSalePayload,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/points-of-sale/" + values.id, values),
  delete: (id: string) => repository.delete(baseUrl + "/points-of-sale/" + id),
})

// User-waybills

const normalizeUserWaybillsPayload = (payload: UserWaybill): UserWaybill => ({
  email: payload.email,
  warehouseId: payload.warehouseId,
  posCode: payload.posCode,
  supWarehouseCode: payload.supWarehouseCode,
  password: payload.password,
  username: payload.username,
})

export const UserWaybillAPI = Object.freeze({
  ...createEntityRepository<
    UserWaybillRequest,
    UserWaybill<"GET">,
    UserWaybill,
    UserWaybill<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeUserWaybillsPayload,
    basePath: "user-waybills",
    utils: UtilsAPI,
  }),
  edit: (values: any) => repository.put("/user-waybills/" + values.id, values),
  delete: (id: string) => repository.delete(baseUrl + "/user-waybills/" + id),
})

//order waybill tempalte

const normalizeOrderWaybillTemplatePayload = (
  payload: OrderWaybillTemplate
): OrderWaybillTemplate => ({
  title: payload.title,
  nomenclatures: payload.nomenclatures,
  warehouseIds: payload.warehouseIds,
})

export const OrderWaybillTemplateAPI = Object.freeze({
  ...createEntityRepository<
    OrderWaybillTemplateRequest,
    OrderWaybillTemplate<"GET">,
    OrderWaybillTemplate,
    OrderWaybillTemplate<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeOrderWaybillTemplatePayload,
    basePath: "order-waybill-templates",
    utils: UtilsAPI,
  }),
  edit: (
    values: OrderWaybillTemplate<"PATCH">
  ): Promise<AxiosResponse<BasicResponse<OrderWaybillTemplate<"GET">>>> =>
    repository.put("/order-waybill-templates/" + values.id, values),
  delete: (id: string) =>
    repository.delete(baseUrl + "/order-waybill-templates/" + id),
})

// Currencies-waybills

const normalizeOrderWaybillPayload = (payload: OrderWaybill): OrderWaybill => ({
  nomenclatures: payload.nomenclatures,
  warehouseId: payload.warehouseId,
  status: payload.status,
  comment: payload.comment,
  name: payload.name,
  phone: payload.phone,
  uuid: payload.uuid,
})

export const OrderWaybillAPI = createEntityRepository<
  OrderWaybillRequest,
  OrderWaybill<"GET">,
  OrderWaybill,
  OrderWaybill<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeOrderWaybillPayload,
  basePath: "order-waybills",
  utils: UtilsAPI,
})

//Corp structure

const normalizeCorpStructurePayload = (
  payload: CorpStructure
): CorpStructure => ({
  title: payload.title,
  code: payload.code,
  startWorkDate: payload.startWorkDate,
})

export const CorpStructureAPI = Object.freeze({
  ...createEntityRepository<
    CorpStructureRequest,
    CorpStructure<"GET">,
    CorpStructure,
    CorpStructure<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeCorpStructurePayload,
    basePath: "corp-structures",
    utils: UtilsAPI,
  }),
  edit: (values: any) =>
    repository.put("/corp-structures/" + values.id, values),
  delete: (id: string) => repository.delete(baseUrl + "/corp-structures/" + id),
})

const normalizeLocationPlanTypePayload = (
  payload: LocationPlanType
): LocationPlanType => ({
  id: payload.id,
  title: payload.title,
  code: payload.code,
  createdAt: payload.createdAt,
})

export const LocationPlanTypeAPI = Object.freeze({
  ...createEntityRepository<
    LocationPlanTypeRequest,
    LocationPlanType<"GET">,
    LocationPlanType,
    LocationPlanType<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeLocationPlanTypePayload,
    basePath: "location-plan-types",
    utils: UtilsAPI,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/location-plan-types/" + values.id, values),
  delete: (id: string) =>
    repository.delete(baseUrl + "/location-plan-types/" + id),
})

//Nomencaltures

const normalizeNomenclaturesPayload = (
  payload: Nomenclature
): Nomenclature => ({
  uuid: payload.uuid,
  name: payload.name,
  description: payload.description,
  type: payload.type,
  code: payload.code,
  categoryId: payload.categoryId,
  taxCategoryId: payload.taxCategoryId,
  mainUnitId: payload.mainUnitId,
  defaultSalePrice: payload.defaultSalePrice,
  modifierIds: payload.modifierIds,
  photos: payload.photos,
})

export const NomenclatureAPI = createEntityRepository<
  NomenclatureRequest,
  Nomenclature<"GET">,
  Nomenclature,
  Nomenclature<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeNomenclaturesPayload,
  basePath: "nomenclatures",
  utils: UtilsAPI,
})

// LocationFormat

const normalizeLocationFormatPayload = (
  payload: LocationFormat
): LocationFormat => ({
  id: payload.id,
  title: payload.title,
  code: payload.code,
  createdAt: payload.createdAt,
})

export const LocationFormatAPI = Object.freeze({
  ...createEntityRepository<
    LocationFormatRequest,
    LocationFormat<"GET">,
    LocationFormat,
    LocationFormat<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeLocationFormatPayload,
    basePath: "location-formats",
    utils: UtilsAPI,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/location-formats/" + values.id, values),
  delete: (id: string) =>
    repository.delete(baseUrl + "/location-formats/" + id),
})

// Manager

// const normalizeManagerPayload = (payload: Manager): Manager => ({
//   id: payload.id,
//   credentialId: payload.credentialId,
//   email: payload.email,
//   username: payload.username,
//   password: payload.password,
//   picId: payload.picId,
//   phone: payload.phone,
//   status: payload.status,
//   fullName: payload.fullName,
//   createdAt: payload.createdAt,
// })

const normalizeManagerPayload = (
  payload: Manager | Manager<"PATCH">,
  type: "post" | "patch" = "post"
): Omit<Manager | Manager<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    credentialId: payload.credentialId,
    email: payload.email,
    username: payload.username,
    password: payload.password,
    picId: payload.picId,
    phone: payload.phone,
    status: payload.status,
    fullName: payload.fullName,
  }
  if (payload.password && type === "post")
    normalized.password = payload.password

  return normalized
}

export const ManagerAPI = Object.freeze({
  ...createEntityRepository<
    ManagerRequest,
    Manager<"GET">,
    Manager,
    Manager<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeManagerPayload,
    basePath: "managers",
    utils: UtilsAPI,
  }),
  edit: (values: any) =>
    repository.put(baseUrl + "/managers/" + values.id, values),
  delete: (id: string) => repository.delete(baseUrl + "/managers/" + id),
  switchManagerStatus: (id: string) => {
    return repository.post(`${baseUrl}/managers/${id}/set-status`)
  },
  changePassword: (
    id: string,
    newPassword: string
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`managers/${id}/change-password`, { newPassword })
  },
  getDepartmentsByManagerId: (id: string) => repository.get(`managers/${id}/departments`),
  getManagers: () => repository.get(baseUrl + "/managers"),
  changeManagerType: (
    departmentId: string,
    id: string,
    type: "PRIMARY" | "SUPPORT"
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`${baseUrl}/managers/${id}/change-manager-type?departmentId=${departmentId}&type=${type}`)
  },
  removeManager: (
    departmentId: string,
    id: string,
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`${baseUrl}/managers/${id}/remove-manager?departmentId=${departmentId}`)
  } 
})

const normalizeAttendancePayload = (
  payload: Attendance | Attendance<"PATCH">
): Omit<Attendance | Attendance<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    comment: payload.comment,
    departmentId: payload.departmentId,
    managerConfirm: payload.managerConfirm,
    employeeId: payload.employeeId,
    positionId: payload.positionId,
    duration: payload.duration,
    status: payload.status,
    syncStatus: payload.syncStatus,
    uuid: payload.uuid,
    dateEnd: payload.dateEnd,
    dateStart: payload.dateStart,
  }
  return normalized
}
export const AttendanceAPI = Object.freeze({
  ...createEntityRepository<
    AttendanceRequest,
    Attendance<"GET">,
    Attendance,
    Attendance<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeAttendancePayload,
    basePath: "attendances",
    utils: UtilsAPI,
  }),
  edit: (values: any) =>
    repository.put(`${HRbaseUrl}/attendances/${values.id}`, values),
  delete: (id: string) => repository.delete(`${HRbaseUrl}/attendances/${id}`),
  getAttendances: () => repository.get(`${HRbaseUrl}/attendances`),
})

const normalizeEmployeePayload = (
  payload: Employee | Employee<"PATCH">
): Omit<Employee | Employee<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    createdAt: payload.createdAt,
    deleted: payload.deleted,
    fullname: payload.fullName,
    emails: payload.emails,
    personalNumber: payload.personalNumber,
    status: payload.status,
    positions: payload.positions,
    updatedAt: payload.updatedAt,
    phones: payload.phones,
  }
  console.log(normalized)
  return normalized
}
export const EmployeeAPI = Object.freeze({
  ...createEntityRepository<
    EmployeeRequest,
    Employee<"GET">,
    Employee,
    Employee<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeEmployeePayload,
    basePath: "employees",
    utils: UtilsAPI,
  }),
  edit: (values: any) =>
    repository.put(`${HRbaseUrl}/employees/${values.id}`, values),
  delete: (id: string) => repository.delete(`${HRbaseUrl}/employees/${id}`),
  getEmployees: () => repository.get(`${HRbaseUrl}/employees`),
})

const normalizePositionPayload = (
  payload: Position | Position<"PATCH">
): Omit<Position | Position<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    createdAt: payload.createdAt,
    deleted: payload.deleted,
    updatedAt: payload.updatedAt,
    condition: payload.condition,
    description: payload.description,
    title: payload.title,
    categoryId: payload.categoryId,
  }
  console.log(normalized)
  return normalized
}
export const PositionAPI = Object.freeze({
  ...createEntityRepository<
    PositionRequest,
    Position<"GET">,
    Position,
    Position<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizePositionPayload,
    basePath: "positions",
    utils: UtilsAPI,
  }),
  edit: (values: any) =>
    repository.put(`${HRbaseUrl}/positions/${values.id}`, values),
  delete: (id: string) => repository.delete(`${HRbaseUrl}/positions/${id}`),
  getPositions: () => repository.get(`${HRbaseUrl}/positions`),
})

export const DepartmentsByManagersAPI = Object.freeze({
  getList: (
    managerId: string,
    params: DepartmentRequest
  ): Promise<AxiosResponse<ListResponse<Department<"GET">>>> => {
    return UtilsAPI.getWithParams(`managers/${managerId}/departments`, params)
  },
  create: (
    managerId: string,
    payload: Department
  ): Promise<AxiosResponse<BasicResponse<Department<"GET">>>> => {
    return repository.post(
      `${baseUrl}/managers/${managerId}/departments`,
      normalizeDepartmentPayload(payload)
    )
  },
  edit: (
    managerId: string,
    payload: Department<"PATCH">
  ): Promise<AxiosResponse<BasicResponse<Department<"GET">>>> => {
    return repository.patch(
      `${baseUrl}/managers/${managerId}/departments/${payload.id}`,
      normalizeDepartmentPayload(payload)
    )
  },
  getById: (
    managerId: string,
    propertyId?: string
  ): Promise<AxiosResponse<BasicResponse<Department<"GET">>>> => {
    return repository.get(
      `${baseUrl}/managers/${managerId}/departments/${propertyId}`
    )
  },
  addManagerToDeparment: (
    id: string,
    request: { departments: { departmentId: string, type: ManagerForDepType }[] }
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`managers/${id}/departments`, request)
  },
})

export const ManagersByDepartmentsAPI = Object.freeze({
  getList: (
    departmentId: string,
    params: ManagerRequest
  ): Promise<AxiosResponse<ListResponse<Manager<"GET">>>> => {
    return UtilsAPI.getWithParams(
      `departments/${departmentId}/managers`,
      params
    )
  },
  removeManagerFromDepartment: (
    id: string,
    manageruserId: string
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`departments/${id}/remove-manager?manageruserId=${manageruserId}`)
  },
  changeManagerType: (
    id: string,
    manageruserId: string,
    type: ManagerForDepType
  ): Promise<AxiosResponse<BasicResponse>> => {
    return repository.post(`departments/${id}/change-manager-type?manageruserId=${manageruserId}&type=${type}`)
  }
})
