import { basicNormalizeAll } from "./meta"
import { Dictionary, handleError } from "ftl-core"
import { FetchAllArgs } from "../../types/entities"
import { Warehouse } from "../../types/warehouse"
import { WarehouseAPI } from "../../repository"

export const fetch1000Warehouses = async ({
  items,
  setItems,
  setIsFetching,
  normalize = basicNormalizeAll,
  labelProp,
}: FetchAllArgs<Warehouse>): Promise<Dictionary<string>> => {
  if (!items)
    try {
      const responseItems = (await WarehouseAPI.getList({ limit: 1000 })).data
        .result.items
      return normalize({ items: responseItems, setItems, labelProp })
    } catch (error: any) {
      handleError({
        error,
        defaultError: "Произошла ошибка при получении данных складов",
        setIsFetching,
      })
      return error
    }
  else return Promise.resolve(items.labels)
}
