import CurrenciesCreateProps from "../props/CurrenciesCreateProps";
import CurrenciesCreacteStyles from "../styles/CurrenciesCreacteStyles";
import CurrenciesCreateStore from "../../../../../domains/AdditionalSettings/Currencies/CurrenciesCreate/store/CurrenciesCreateStore";
import { useGate, useStore } from "effector-react";
import CurrenciesCreateEntity from "../../../../../domains/AdditionalSettings/Currencies/CurrenciesCreate/entity/CurrenciesCreateEntity";
import { history } from "ftl-core";
import { Box, LinearProgress } from "@material-ui/core";
import {
  FTLBreadcrumbs,
  FTLPageHeader,
  FTLTextField,
  FTLToolbar,
} from "ftl-uikit";

const breadCrumps = [
  { label: "Валюты", to: "/Currencies" },
  { label: "Создание", to: "#" },
];

const CurrenciesCreatePage = (props: CurrenciesCreateProps) => {
  const classes = CurrenciesCreacteStyles();
  const store = CurrenciesCreateStore;
  useGate(store.gate);
  const formErrors = useStore(store.store.errors);
  const formStore: CurrenciesCreateEntity = useStore(store.store.values);
  const formIsValid = useStore(store.store.isValid);
  const formMeta = useStore(store.meta);

  store.didSendData.watch((payload) => {
    store.store.fill(store.store.values.defaultState)
    history.push(`/Currencies`);
  });

  return (
    <div className={classes.root}>
      <Box marginBottom={"auto"}>
        <FTLBreadcrumbs items={breadCrumps} />
        {formMeta.isLoad ? (
          <LinearProgress color={"secondary"} />
        ) : (
          null
        )}

        <FTLPageHeader title={"Создание валюты"} BoxProps={{ mb: 6 }} />
        <form
          noValidate
          id={"currencies-create-form"}
          onSubmit={(e) => {
            e.preventDefault();
            store.store.submit();
          }}
        >
          <Box display={"grid"} mt={6} gridGap={"24px"} width={"360px"}>
            <FTLTextField
              required
              name={"title"}
              label={"Название"}
              value={formStore.title}
              onChange={(e) => store.store.fields.title.set(e.target.value)}
              error={formErrors.title && formMeta.showErrors ? true : false}
              helperText={formErrors.title}
              fullWidth
              placeholder={"Введите название"}
            />
          </Box>
          <Box display={"grid"} mt={6} gridGap={"24px"} width={"360px"}>
            <FTLTextField
              required
              name={"description"}
              label={"Код"}
              value={formStore.code}
              onChange={(e) => store.store.fields.code.set(e.target.value)}
              error={formErrors.code && formMeta.showErrors ? true : false}
              helperText={formErrors.code}
              fullWidth
              placeholder={"Введите код"}
            />
          </Box>
        </form>
      </Box>
      <FTLToolbar
        position={"sticky"}
        onSaveBtnId={"form"}
        disabled={formIsValid == false || formMeta.isLoad == true}
        onSaveMessage="Сохранить"
        onCancel={() => history.goBack()}
        showSaveButton={true}
        onSave={() => {
          store.store.submit();
        }}
      />
    </div>
  );
};

export default CurrenciesCreatePage