//components
import { FTLPageHeader, FTLStatusTag, FTLTable } from "ftl-uikit"

import { FTLObjectUtils, useListPage, useLocalStoreState, FiltersState } from "ftl-core"
import { OrderWaybillAPI } from "../../repository"
import { normalizeOrderWaybillFilters, normalizeOrderWaybills, orderWaybillStatusLabels, orderWaybillStatusTagStatus } from "./model"
import { FTLListPage } from "ftl-dashboards-templates"
import { useCallback, useState } from "react"
import { MetaEntityState } from "../../types/entities"
import { fetch1000Warehouses } from "../../shared/entities/warehouses"
import { CellProps, Column } from "react-table"
import { OrderWaybill, OrderWaybillStatus } from "../../types/orderWaybill"

const columns: Column<OrderWaybill<"GET">>[] = [
  {
    Header: "Заявка",
    accessor: "name",
    width: "40%",
    minWidth: 200,
    disableSortBy: true,
  },
  {
    Header: "Статус",
    accessor: "status",
    width: "25%",
    minWidth: 100,
    disableSortBy: true,
    Cell: (cell: React.PropsWithChildren<CellProps<OrderWaybill<"GET">, OrderWaybillStatus>>) => {
      const value = cell.value
      return (
        <FTLStatusTag status={orderWaybillStatusTagStatus[value]}>
          {orderWaybillStatusLabels[value]}
        </FTLStatusTag>
      )
    },
  },
  {
    Header: "Склад",
    accessor: "warehouseName",
    width: "25%",
    minWidth: 200,
    disableSortBy: true,
  },
  {
    Header: "Создан",
    accessor: "createdAt",
    type: "date",
    reverse: true,
    align: "right",
    width: "20%",
    minWidth: 150,
  },
]

const OrderWaybillPage = () => {
  const [filters, tableSettings, setFilters, setTableSettings] =
    useLocalStoreState<FiltersState, OrderWaybill<"GET">>("orderWaybillsList")

  const [warehouses, setWarehouses] = useState<MetaEntityState | null>(null)

  const fetchOrderWaybills = useCallback(
    () =>
      fetch1000Warehouses({
        items: warehouses,
        setItems: setWarehouses,
      }),
    [warehouses]
  )

  const [renderState] = useState<boolean>(false)

  const { data, pageCount, isFetching, query, setQuery } = useListPage({
    filters,
    setFilters,
    tableSettings,
    getList: OrderWaybillAPI.getList,
    normalizeFilters: normalizeOrderWaybillFilters,
    normalizeResponse: (items, context) =>
      normalizeOrderWaybills(items, context),
    getMeta: fetchOrderWaybills,
  },
  [filters, tableSettings, renderState]
  )

  return (
    <FTLListPage
      headerComponent={<FTLPageHeader title="Заявки" />}
      tableComponent={
        <FTLTable
          data={data}
          columns={columns}
          pageCount={pageCount}
          isFetching={isFetching}
          tableState={tableSettings}
          setTableState={setTableSettings}
          actionsSize={2}
          isSearching={Boolean(FTLObjectUtils.getNotEmptyFieldsCount(filters))}
        />
      }
      searchProps={{
        value: query,
        onChange: (value) => setQuery(value),
        placeholder: "Поиск",
      }}
    />
  )
}
export default OrderWaybillPage
